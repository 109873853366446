import { useEffect } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import Button from '../components/Button';
import Text from '../components/Text';
import { fetchAdequatePixel } from '../utils/datautils';

const Input = styled.textarea`
  border: none;
  width: 100%;
  min-height: 16.7rem;
  padding: 1rem;
  border: 1px solid #222529;
  border-radius: 0.4rem;
  margin-top: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.1rem;
  /* or 162% */
  background: none;
  letter-spacing: 0.15px;

  /* gray 9 */
  color: #222529;

  &::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2.1rem;
    letter-spacing: 0.15px;
    color: #888d96;
  }

  &:focus {
    outline: none;
  }
`;

const InputWorryPage = ({ value, onChange, onButtonClick }) => {
  const pixelId = fetchAdequatePixel();
  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    fbq('trackSingle', pixelId, 'ViewContent');

    amplitude.getInstance().logEvent('page_second', { referral: 'postpay_painful_v5' });
  }, []);

  return (
    <>
      <Text style={{ width: '100%', fontSize: '1.4rem', paddingTop: '1.6rem' }}>
        <span style={{ fontSize: '2.4rem' }}>
          어떤 고민을 <br /> 가지고 계신가요?
        </span>
        <br />
        <br />
        <span style={{ color: '#E1354C' }}>
          누구에게도 말하지 못했던 고민까지 알려주세요.
          <br />
          비밀 유지를 100% 보장합니다.
        </span>
        <br />
        <br />
        <span style={{ color: '#E1354C' }}>다른 분들은 이런 고민을 해결하셨어요.</span>
        <br />
        <br />
        "6개월 연애하고 헤어졌어요. 재결합도 3번 했어서 이젠 정말 끊어내야지 하면서도 맘처럼 안돼요. 행복하게 재회하는
        것 이제는 포기해야 할까요?"
        <br />
        <br />
        "연락만 하는 사람이 있는데, 진짜 속마음이 뭘까궁금하고 답답해요"
        <br />
        <br />
        "2주 전에 제 잘못으로 헤어졌는데, 언제 연락하면 받아줄까요? 어떻게 말을 꺼내야 할 지 모르겠어요"
        <br />
        <br />
        "남편과 사이가 멀어졌어요. 말도 없고... 이제는 내가 모르는 사람인 것 같아요"
      </Text>

      <Input placeholder={'고민 내용을 입력해주세요 (30자 이상)'} value={value} onChange={onChange} />
      <div
        style={{
          marginTop: '1.6rem',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
        <Button
          id="cta-finish-writing"
          text="무료 상담 받기"
          width="50%"
          color="#fff"
          backgroundColor={value.length >= 30 ? '#222529' : '#CFD4DA'}
          style={{
            fontFamily: 'Roboto',
            fontSize: '2rem',
            fontWeight: 700,
            lineHeight: '6.4rem',
            marginBottom: '1.6rem',
          }}
          onClickHandler={
            value.length >= 30
              ? () => {
                  fbq('trackSingle', pixelId, 'SubmitApplication', { value: 126, currency: 'KRW' });
                  onButtonClick();
                }
              : () => {
                  alert('고민을 30자 이상 작성해주세요.');
                }
          }
        />
      </div>
    </>
  );
};

export default InputWorryPage;
